<script>
    import { fly, fade } from 'svelte/transition';
    import { onMount } from 'svelte';
    import Icon from 'svelte-awesome';
    import {
        envelope,
        times,
        whatsapp,
        facebook,
        instagram,
        envelopeO,
        phone,
    } from 'svelte-awesome/icons';
    let showFloat = false;
    onMount(() => {
        showFloat = true;
    });
    let open = false;

    document.mobileAndTabletCheck = function () {
        let check = false;
        (function (a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                    a,
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    a.substr(0, 4),
                )
            )
                check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    };
</script>

{#if showFloat}
    <a
        href="/"
        on:click|preventDefault={() => (open = !open)}
        class="fab bg-gradient-to-r from-blue-400 to-indigo-500 right-4 bottom-4 sm:right-8 sm:bottom-8 flex no-underline w-16 h-16 fixed text-white rounded-full z-50"
        in:fly={{ y: 200, duration: 1000 }}
        on:blur={() => setTimeout(() => (open = false), 100)}
    >
        <!-- <Icon
            data={open ? times : envelope}
            class="text-white w-8 h-8 m-auto"
        /> -->
        <!-- <svg
            version="1.1"
            viewBox="0 0 33.834 33.834"
            class="w-8 h-8 m-auto"
            style="fill: rgb(255, 255, 255);"
        > -->
        <!-- <path
                d="M32.253,29.334v4.5H1.581v-4.501c0-2.125,1.832-4.741,4.07-5.804l4.98-2.366l3.457,7.204l1.77-4.799
		c0.349,0.066,0.695,0.154,1.059,0.154s0.709-0.088,1.059-0.154l1.68,4.563l3.389-7.048l5.141,2.445
		C30.421,24.591,32.253,27.207,32.253,29.334z M6.105,13.562v-3.25c0-0.551,0.287-1.034,0.72-1.312c0.581-5.058,4.883-9,10.094-9
		s9.514,3.942,10.096,9c0.432,0.278,0.719,0.761,0.719,1.312v3.25c0,0.863-0.699,1.563-1.563,1.563s-1.563-0.7-1.563-1.563v-0.683
		c-0.846,4.255-3.961,8.205-7.688,8.205c-3.727,0-6.842-3.95-7.688-8.205v0.683c0,0.7-0.465,1.286-1.1,1.485
		c0.622,2.117,2.002,3.946,3.908,5.146c0.352-0.116,0.796-0.094,1.227,0.13c0.692,0.36,1.045,1.06,0.783,1.56
		c-0.261,0.5-1.033,0.612-1.729,0.251c-0.508-0.265-0.83-0.71-0.864-1.126c-2.183-1.396-3.731-3.533-4.37-5.998
		C6.513,14.78,6.105,14.22,6.105,13.562z M7.89,8.635c0.047,0.003,0.092,0.004,0.137,0.021C8.14,8.698,8.222,8.779,8.279,8.874
		c0.339,0.144,0.609,0.407,0.775,0.733C9.515,5.286,12.855,3,16.917,3c4.062,0,7.402,2.286,7.863,6.607
		c0.229-0.449,0.664-0.77,1.185-0.837c-0.676-4.393-4.47-7.771-9.048-7.771C12.386,1,8.622,4.309,7.89,8.635z"
            /> -->
        <svg
            version="1.1"
            viewBox="0 0 612 612"
            class="w-8 h-8 m-auto"
            style="fill: rgb(255, 255, 255);"
        >
            <path
                d="M257.938,336.072c0,17.355-14.068,31.424-31.423,31.424c-17.354,0-31.422-14.068-31.422-31.424
		c0-17.354,14.068-31.423,31.422-31.423C243.87,304.65,257.938,318.719,257.938,336.072z M385.485,304.65
		c-17.354,0-31.423,14.068-31.423,31.424c0,17.354,14.069,31.422,31.423,31.422c17.354,0,31.424-14.068,31.424-31.422
		C416.908,318.719,402.84,304.65,385.485,304.65z M612,318.557v59.719c0,29.982-24.305,54.287-54.288,54.287h-39.394
		C479.283,540.947,379.604,606.412,306,606.412s-173.283-65.465-212.318-173.85H54.288C24.305,432.562,0,408.258,0,378.275v-59.719
		c0-20.631,11.511-38.573,28.46-47.758c0.569-84.785,25.28-151.002,73.553-196.779C149.895,28.613,218.526,5.588,306,5.588
		c87.474,0,156.105,23.025,203.987,68.43c48.272,45.777,72.982,111.995,73.553,196.779C600.489,279.983,612,297.925,612,318.557z
		 M497.099,336.271c0-13.969-0.715-27.094-1.771-39.812c-24.093-22.043-67.832-38.769-123.033-44.984
		c7.248,8.15,13.509,18.871,17.306,32.983c-33.812-26.637-100.181-20.297-150.382-79.905c-2.878-3.329-5.367-6.51-7.519-9.417
		c-0.025-0.035-0.053-0.062-0.078-0.096l0.006,0.002c-8.931-12.078-11.976-19.262-12.146-11.31
		c-1.473,68.513-50.034,121.925-103.958,129.46c-0.341,7.535-0.62,15.143-0.62,23.08c0,28.959,4.729,55.352,12.769,79.137
		c30.29,36.537,80.312,46.854,124.586,49.59c8.219-13.076,26.66-22.205,48.136-22.205c29.117,0,52.72,16.754,52.72,37.424
		c0,20.668-23.604,37.422-52.72,37.422c-22.397,0-41.483-9.93-49.122-23.912c-30.943-1.799-64.959-7.074-95.276-21.391
		C198.631,535.18,264.725,568.41,306,568.41C370.859,568.41,497.099,486.475,497.099,336.271z M550.855,264.269
		C547.4,116.318,462.951,38.162,306,38.162S64.601,116.318,61.145,264.269h20.887c7.637-49.867,23.778-90.878,48.285-122.412
		C169.37,91.609,228.478,66.13,306,66.13c77.522,0,136.63,25.479,175.685,75.727c24.505,31.533,40.647,72.545,48.284,122.412
		H550.855L550.855,264.269z"
            />
        </svg>
        <!-- <svg viewBox="0 0 33.834 33.834" width="33.834" height="33.834" style="enable-background:new 0 0 33.834 33.834;" xmlns="http://www.w3.org/2000/svg">
            <g transform="matrix(1, 0, 0, 1, -0.028031, 0)">
              <path d="M32.253,29.334v4.5H1.581v-4.501c0-2.125,1.832-4.741,4.07-5.804l4.98-2.366l3.457,7.204l1.77-4.799 c0.349,0.066,0.695,0.154,1.059,0.154s0.709-0.088,1.059-0.154l1.68,4.563l3.389-7.048l5.141,2.445 C30.421,24.591,32.253,27.207,32.253,29.334z M6.105,13.562v-3.25c0-0.551,0.287-1.034,0.72-1.312c0.581-5.058,4.883-9,10.094-9 s9.514,3.942,10.096,9c0.432,0.278,0.719,0.761,0.719,1.312v3.25c0,0.863-0.699,1.563-1.563,1.563s-1.563-0.7-1.563-1.563v-0.683 c-0.846,4.255-3.961,8.205-7.688,8.205c-3.727,0-6.842-3.95-7.688-8.205v0.683c0,0.7-0.465,1.286-1.1,1.485 c0.622,2.117,2.002,3.946,3.908,5.146c0.352-0.116,0.796-0.094,1.227,0.13c0.692,0.36,1.045,1.06,0.783,1.56 c-0.261,0.5-1.033,0.612-1.729,0.251c-0.508-0.265-0.83-0.71-0.864-1.126c-2.183-1.396-3.731-3.533-4.37-5.998 C6.513,14.78,6.105,14.22,6.105,13.562z M7.89,8.635c0.047,0.003,0.092,0.004,0.137,0.021C8.14,8.698,8.222,8.779,8.279,8.874 c0.339,0.144,0.609,0.407,0.775,0.733C9.515,5.286,12.855,3,16.917,3c4.062,0,7.402,2.286,7.863,6.607 c0.229-0.449,0.664-0.77,1.185-0.837c-0.676-4.393-4.47-7.771-9.048-7.771C12.386,1,8.622,4.309,7.89,8.635z" style="fill: rgb(255, 255, 255);"/>
            </g>
          </svg> -->
    </a>
    {#if open}
        {#if document.mobileAndTabletCheck()}
            <ul
                class="right-4 sm:right-8 fixed mb-6 bottom-20 z-10"
                transition:fly={{ y: 200, duration: 200 }}
            >
                <li>
                    <a href="whatsapp://send?phone=51965742428">
                        <Icon
                            data={whatsapp}
                            class="text-white w-10 h-10 m-auto"
                        />
                    </a>
                </li>
                <li>
                    <a href="fb://page/210485279013191">
                        <Icon
                            data={facebook}
                            class="text-white w-10 h-10 m-auto"
                        />
                    </a>
                </li>
                <li>
                    <a href="instagram://user?username=skyviajesperu">
                        <Icon
                            data={instagram}
                            class="text-white w-10 h-10 m-auto"
                        />
                    </a>
                </li>
                <li>
                    <a href="mailto:imagen@skyperu.com">
                        <Icon
                            data={envelopeO}
                            class="text-white w-10 h-10 m-auto"
                        />
                    </a>
                </li>
                <li>
                    <a href="tel:51965742428" class="">
                        <Icon
                            data={phone}
                            class="text-white w-10 h-10 m-auto"
                        />
                    </a>
                </li>
            </ul>
        {:else}
            <ul
                class="right-4 sm:right-8 fixed mb-6 bottom-20 z-10"
                transition:fly={{ y: 200, duration: 200 }}
            >
                <li>
                    <a href="https://wa.me/51965742428">
                        <Icon
                            data={whatsapp}
                            class="text-white w-10 h-10 m-auto"
                        />
                    </a>
                </li>
                <li>
                    <a href="https://www.facebook.com/skyviajes/">
                        <Icon
                            data={facebook}
                            class="text-white w-10 h-10 m-auto"
                        />
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/skyviajesperu/">
                        <Icon
                            data={instagram}
                            class="text-white w-10 h-10 m-auto"
                        />
                    </a>
                </li>
                <li>
                    <a href="mailto:imagen@skyperu.com">
                        <Icon
                            data={envelopeO}
                            class="text-white w-10 h-10 m-auto"
                        />
                    </a>
                </li>
                <li>
                    <a href="tel:51965742428" class="">
                        <Icon
                            data={phone}
                            class="text-white w-10 h-10 m-auto"
                        />
                    </a>
                </li>
            </ul>
        {/if}
    {/if}
{/if}

<style>
    ul li {
        @apply mb-2;
    }
    ul li a {
        @apply bg-gradient-to-r from-blue-400 to-indigo-500 text-4xl flex no-underline text-white rounded-full shadow-lg w-16 h-16;
    }

    .fab {
        animation: rotate-in 0.5s;
    }

    .fab:active {
        animation: rotate-out 0.5s;
    }

    @keyframes rotate-in {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes rotate-out {
        from {
            transform: rotate(360deg);
        }
        to {
            transform: rotate(0deg);
        }
    }
</style>
