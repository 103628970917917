<script>
    import Login from './Login.svelte';
    import Pages from './Pages.svelte';
    import Tailwindcss from './Tailwindcss.svelte';
    import { reservation } from './store';
</script>

<Tailwindcss />
{#if $reservation}
    <Pages />
{:else}
    <Login />
{/if}
