<script>
    export let pageDetail;

    import { getContext } from 'svelte';
    import Supplier from './Supplier.svelte';
    import time, { dayjs } from './time';

    import Icon from 'svelte-awesome';
    import { chevronLeft, clockO, male } from 'svelte-awesome/icons';

    let {
        package: { name, image },
        start_date,
        comple_date,
    } = pageDetail;

    const { open } = getContext('simple-modal');

    const showSupplier = (content, title) => {
        open(Supplier, { content, title });
    };

    const hasContent = text =>
        text.replace(/(<([^>]+)>)/gi, '') ? true : false;

    const showComple = dayjs(comple_date).diff(start_date, 'day') > 0;
</script>

<div class="max-w-screen-xl mx-auto">
    <header>
        <div
            class="mx-auto sm:my-5 inline-flex w-full justify-center items-center"
        >
            <a
                on:click|preventDefault={() => {
                    pageDetail = null;
                }}
                href="/"
                class="px-3 py-6 md:px-0 sm:absolute sm:left-0 text-xl md:left-3 flex flex-col lg:flex-row"
            >
                <Icon data={chevronLeft} class="w-8 h-8 m-auto flex" />
                <span class="hidden sm:flex">Atrás</span>
            </a>
            <a href="/" class="flex mb-0 font-header justify-center">
                <img
                    alt="Sky"
                    src="logo.svg"
                    class="w-10 sm:w-14 md:w-16 mr-4 flex"
                />
                <span
                    class="flex text-3xl leading-none sm:text-5xl md:text-6xl sm:leading-10 md:leading-10 font-medium flex-wrap"
                >
                    sky viajes y turismo
                </span>
            </a>
        </div>
    </header>

    <main class="">
        <div class="w-full max-w-screen-md mx-auto relative h-64">
            <div
                class="absolute left-0 bottom-0 w-full h-full z-10"
                style="background-image: linear-gradient(180deg,transparent,rgba(0,0,0,.7));"
            />
            <img
                alt={name}
                src={image ||
                    'https://i.postimg.cc/05Mm1kPS/imageedit-14-9160457711.jpg'}
                class="absolute left-0 top-0 w-full h-full z-0 object-cover"
            />
            <a
                href="/"
                class="block bg-black z-21 absolute top-0 right-0 opacity-90 px-4 py-1 m-4 sm:text-xl"
            >
                <span class="font-bold text-white">
                    <Icon data={clockO} class="w-8 h-8 m-auto flex" />
                    <span
                        use:time={{
                            timestamp: start_date,
                            format: 'dddd DD',
                        }}
                        class="capitalize"
                    />
                    {#if showComple}
                        <span>-</span>
                        <span
                            use:time={{
                                timestamp: comple_date,
                                format: 'dddd DD',
                            }}
                            class="capitalize"
                        />
                    {/if}
                </span>
            </a>
            <div class="p-4 absolute bottom-0 left-0 z-20">
                <h2 class="text-4xl font-semibold text-gray-100 leading-tight">
                    {name}
                </h2>
            </div>
        </div>

        <div
            class="p-4 lg:px-0 text-gray-700 max-w-screen-md mx-auto text-lg leading-relaxed bg-white"
        >
            <section class="text-gray-600 body-font">
                <div class="container px-1 mx-auto">
                    <h2 class="font-bold text-xl mb-5">Itinerario</h2>
                    <div class="flex flex-wrap w-full">
                        {#each pageDetail.service_requests as { service_order, service, date_in, time_in, date_out }}
                            <div class="py-2" class:hidden={!service.show}>
                                <div class="h-full flex items-start">
                                    <div
                                        class="flex-shrink-0 flex flex-col text-center leading-none shadow-lg border p-2"
                                    >
                                        <span
                                            class="text-gray-500 pb-2 border-gray-200 capitalize"
                                            use:time={{
                                                timestamp: date_in,
                                                format: 'DD',
                                            }}
                                            translate="no"
                                        >
                                            Feb
                                        </span>
                                        <span
                                            class="font-medium text-sm text-gray-800 title-font leading-none"
                                            use:time={{
                                                timestamp: date_in,
                                                format: 'MMMM',
                                            }}
                                        >
                                            01
                                        </span>
                                    </div>
                                    <div class="flex-grow pl-6">
                                        <h2
                                            class="tracking-widest text-xs title-font font-medium text-indigo-500 mb-1"
                                            on:click={() => {
                                                if (
                                                    hasContent(
                                                        service.supplier
                                                            .description,
                                                    )
                                                ) {
                                                    showSupplier(
                                                        service.supplier
                                                            .description,
                                                        service.supplier.name,
                                                    );
                                                }
                                            }}
                                            class:hidden={!service.show_supplier}
                                            class:cursor-pointer={hasContent(
                                                service.supplier.description,
                                            )}
                                            class:underline={hasContent(
                                                service.supplier.description,
                                            )}
                                        >
                                            {service.supplier.name}
                                        </h2>
                                        <h1
                                            class="title-font text-xl font-medium text-gray-900 mb-3"
                                        >
                                            {service.description}
                                        </h1>
                                        <p class="leading-relaxed mb-5">
                                            {service.description_full}
                                        </p>
                                        {#if service_order && service.show_contact}
                                            <a
                                                href="/"
                                                class="inline-flex items-center"
                                            >
                                                <Icon
                                                    data={male}
                                                    class="w-8 h-8 m-auto flex"
                                                />
                                                <span
                                                    class="flex-grow flex flex-col pl-3"
                                                >
                                                    <small>A cargo de:</small>
                                                    <span
                                                        class="title-font font-medium text-gray-900"
                                                    >
                                                        {service_order
                                                            .supplier_contact
                                                            .name}
                                                    </span>
                                                </span>
                                            </a>
                                        {/if}
                                    </div>
                                </div>
                            </div>
                        {/each}
                    </div>
                </div>
            </section>
        </div>
    </main>
</div>
