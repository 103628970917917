<script>
    import { reservation } from './store';

    import Tours from './Tours.svelte';
</script>

<h2 class="font-semibold text-2xl mb-2 text-gray-700">RESERVA</h2>
<p class="capitalize text-xl text-justify my-4 lg:text-center text-gray-700">
    <li class="font-bold">{$reservation.client.name}</li>
    {#each $reservation.passengers as { name }}
        <li>{name}</li>
    {/each}
</p>
<Tours />
