<script>
    import { reservation } from './store';
</script>

<div class="bg-white px-1 rounded-md w-full shadow-xl">
    <div class="overflow-x-auto mt-6">
        <table class="table-auto border-collapse w-full">
            <thead class="text-left">
                <tr
                    class="rounded-lg text-sm font-medium text-gray-700"
                    style="font-size: 0.9674rem"
                >
                    {#if $reservation.messages.length > 1}
                        <th class="px-4 py-2 bg-gray-200 w-4">#</th>
                    {/if}
                    <th class="px-4 py-2 bg-gray-200 text-lg">
                        Indicaciones importantes
                    </th>
                </tr>
            </thead>
            <tbody class="text-sm font-normal text-gray-700 text-left">
                {#each $reservation.messages as message, index}
                    <tr
                        class="hover:bg-gray-100 border-b border-gray-200 py-10"
                    >
                        {#if $reservation.messages.length > 1}
                            <td class="px-4 py-4 w-4">
                                <span class="text-gray-600 font-bold ">
                                    {index + 1}.
                                </span>
                            </td>
                        {/if}
                        <td class="px-4 py-4">{message}</td>
                    </tr>
                {/each}
            </tbody>
        </table>
    </div>
</div>

<style>
    thead tr th:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    thead tr th:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    tbody tr td:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 0px;
    }
    tbody tr td:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 0px;
    }
</style>
