<script>
    import time from './time';

    import Icon from 'svelte-awesome';
    import { clockO } from 'svelte-awesome/icons';

    export let item;
    // export const pageDetail = false;

    let {
        package: { image, name, description },
        start_date,
        comple_date,
    } = item;
    // image = '';
    // description = '';
</script>

<!-- cursor-pointer -->
<!-- on:click={() => {
    // pageDetail = item;
}} -->
<div
    class="bg-white shadow-2xl rounded-lg mb-6 tracking-wide bg-gradient-to-b from-white to-blue-50"
>
    <div class="md:flex-shrink-0 bg-gray-200 rounded-t-lg">
        <div
            class="sv-bg h-48 rounded-t-lg w-full bg-cover bg-center relative"
            style="--img: url('{image ||
                'https://i.postimg.cc/05Mm1kPS/imageedit-14-9160457711.jpg'}');"
        >
            <div
                class="bg-black bg-opacity-50 absolute p-2 rounded-tl-xl rounded-br-lg"
            >
                <span
                    class="text-white opacity-100 font-bold capitalize"
                    use:time={{
                        timestamp: start_date,
                        format: 'dddd DD',
                    }}
                >
                    Loading...
                </span>
            </div>
        </div>
    </div>
    <div class="px-4 py-2 mt-2 hover">
        <h2
            class="title font-bold text-base text-gray-800 text-justify lg:text-left tracking-normal line-clamp-1 hover:line-clamp-none"
        >
            {name}
            <!-- <br />
            <br /> -->
        </h2>
        {#if description}
            <p
                class="text-sm text-gray-700 my-4 text-justify md:line-clamp-4 hover:line-clamp-none"
            >
                {description}
            </p>
        {:else}
            <p
                class="text-sm text-gray-700 my-4 text-justify md:line-clamp-4 hidden md:block"
            >
                <br />
                <br />
                <br />
                <br />
            </p>
        {/if}

        <div class="flex my-3 justify-end px-4 items-center">
            <div class="user-logo w-max">
                <Icon data={clockO} class="w-16 h-16 m-auto flex p-2" />
            </div>
            <h2 class="text-sm tracking-tighter text-gray-900">
                <div>
                    <span class="font-bold">Inicia</span>
                    <span
                        class="text-gray-600"
                        use:time={{
                            timestamp: start_date,
                            format: 'DD MMMM YYYY [a las] h:mm A',
                        }}
                    >
                        Loading...
                    </span>
                </div>
                <div>
                    <span class="font-bold">Hasta:</span>
                    <span
                        class="text-gray-600"
                        use:time={{
                            timestamp: comple_date,
                            format: 'DD MMMM YYYY [a las] h:mm A',
                        }}
                    >
                        Loading...
                    </span>
                </div>
            </h2>
        </div>
    </div>
</div>

<style>
    /* :root {
        --img: url('https://skyperu.com/wp-content/uploads/2021/01/Colca7-1024x466-1.jpg');
    } */
    .sv-bg {
        background-image: var(--img);
    }
</style>
