import { writable } from 'svelte/store';
import watchMedia from 'svelte-media';

export const reservation = writable(null);
export const promoPosts = writable([]);

export const blogPosts = writable([]);
export const configuration = writable({});

const mediaqueries = {
    sm: '(min-width: 640px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 1024px)',
    xl: '(min-width: 1280px)',
    '2xl': '(min-width: 1536px)',
    landscape: '(orientation: landscape) and (max-height: 499px)',
    noanimations: '(prefers-reduced-motion: reduce)',
};

export const media = watchMedia(mediaqueries);
