<script>
    import InfoReservation from './InfoReservation.svelte';
    let showInfo = false;
    let showMessage = false;
    import { slide } from 'svelte/transition';
    import * as animateScroll from 'svelte-scrollto';
    import { reservation } from './store';
    import MessageReservation from './MessageReservation.svelte';
</script>

{#if !showInfo && !showMessage}
    <div class="w-full flex absolute justify-center">
        <button
            on:click={() => {
                showInfo = true;
                showMessage = false;
                animateScroll.scrollToTop({ offset: 90 });
            }}
            class="rounded-md bg-gradient-to-r from-blue-400 to-indigo-500 text-sm sm:text-lg text-white py-2 px-8 mx-4 w-1/2 md:w-auto"
        >
            <span>Ver detalles</span>
            <span class="whitespace-nowrap ">
                ({$reservation.passengers.length + 1}
                {`${
                    $reservation.passengers.length == 0
                        ? 'pasajero'
                        : 'pasajeros'
                }`})
            </span>
        </button>

        {#if $reservation.messages?.length}
            <!--  -->
            <button
                on:click={() => {
                    showInfo = false;
                    showMessage = true;
                    animateScroll.scrollToTop({ offset: 90 });
                }}
                class="rounded-md bg-gradient-to-r from-blue-400 to-indigo-500 text-sm sm:text-lg text-white py-2 px-8 mx-4 w-1/2 md:w-auto"
            >
                Indicaciones importantes
            </button>
        {/if}
    </div>
{/if}
{#if showInfo}
    <div class="place-content-center" transition:slide>
        <div
            class="py-4 px-2 text-center w-full bg-gradient-to-b from-white to-blue-100 rounded-xl"
        >
            <InfoReservation />
            <div class="mt-10">
                <button
                    on:click={() => {
                        showInfo = !showInfo;
                        animateScroll.scrollToTop();
                    }}
                    class="rounded-md bg-gradient-to-r from-blue-400 to-indigo-500 text-sm sm:text-lg text-white py-3 px-8 inline"
                >
                    Ocultar
                </button>
            </div>
        </div>
    </div>
{:else if showMessage}
    <div class="place-content-center" transition:slide>
        <div
            class="py-4 px-2 text-center w-full bg-gradient-to-b from-white to-blue-100 rounded-xl"
        >
            <MessageReservation />
            <div class="mt-10">
                <button
                    on:click={() => {
                        showMessage = !showMessage;
                        animateScroll.scrollToTop();
                    }}
                    class="rounded-md bg-gradient-to-r from-blue-400 to-indigo-500 text-sm sm:text-lg text-white py-3 px-8 inline"
                >
                    Ocultar
                </button>
            </div>
        </div>
    </div>
{/if}
