<script>
    export let content;
    export let title;
</script>

<h1 class="text-2xl py-3">{title}</h1>
<div class="unreset jodit-wysiwyg">
    {@html content}
</div>
<div class="h-4" />

<style global lang="scss">
    .unreset {
        a {
            @apply text-blue-700 underline;
        }
        p {
            @apply my-4;
        }

        blockquote,
        figure {
            @apply my-4 mx-10;
        }

        hr {
            @apply border;
        }

        h1 {
            @apply text-4xl font-bold my-2;
        }

        h2 {
            @apply text-2xl font-bold my-3;
        }

        h3 {
            @apply text-lg font-bold my-4;
        }

        h4 {
            @apply text-base font-bold my-5;
        }

        h5 {
            @apply text-sm font-bold my-6;
        }

        h6 {
            @apply text-xs font-bold my-10;
        }

        article,
        aside,
        nav,
        section {
            h1 {
                @apply text-2xl font-bold my-3;
            }

            article,
            aside,
            nav,
            section {
                h1 {
                    @apply text-lg font-bold my-4;
                }

                article,
                aside,
                nav,
                section {
                    h1 {
                        @apply text-base font-bold my-5;
                    }

                    article,
                    aside,
                    nav,
                    section {
                        h1 {
                            @apply text-sm font-bold my-6;
                        }

                        article,
                        aside,
                        nav,
                        section {
                            h1 {
                                @apply text-xs font-bold my-10;
                            }
                        }
                    }
                }
            }
        }

        ul,
        menu {
            // should be my-4 for proper reset, but thats ugly af
            @apply list-disc my-1 pl-10;
        }

        ol {
            @apply list-decimal my-4 pl-10;
        }

        ul,
        ol {
            ul {
                list-style-type: circle;
            }

            ul,
            ol {
                ul {
                    list-style-type: square;
                }
            }
        }

        dd {
            @apply pl-10;
        }

        dl {
            @apply my-4;
        }

        ul,
        ol,
        menu,
        dl {
            ul,
            ol,
            menu,
            dl {
                margin: 0;
            }
        }

        legend {
            @apply py-0 px-1;
        }

        fieldset {
            @apply my-0 mx-1 pt-0 px-1 pb-2;
        }

        b,
        strong {
            font-weight: bold;
        }

        pre {
            @apply my-4;
        }
    }
    .jodit-wysiwyg {
        .ct-grid {
            stroke: rgba(255, 255, 255, 0.2);
            stroke-width: 1px;
            stroke-dasharray: 2px;
        }

        .ct-series-a .ct-point,
        .ct-series-a .ct-line,
        .ct-series-a .ct-bar,
        .ct-series-a .ct-slice-donut {
            stroke: rgba(255, 255, 255, 0.8);
        }

        .ct-label.ct-horizontal.ct-end {
            align-items: flex-start;
            justify-content: flex-start;
            text-align: left;
            text-anchor: start;
        }

        .ct-label {
            color: rgba(255, 255, 255, 0.7);
        }

        .ct-chart-line .ct-label,
        .ct-chart-bar .ct-label {
            display: block;
            display: flex;
        }

        .ct-label {
            fill: rgba(0, 0, 0, 0.4);
            line-height: 1;
        }

        blockquote footer:before,
        blockquote small:before {
            content: '\2014 \00A0';
        }

        small {
            font-size: 80%;
        }

        h1 {
            font-size: 3em;
            line-height: 1.15em;
        }

        h2 {
            font-size: 2.4em;
        }

        h3 {
            font-size: 1.825em;
            line-height: 1.4em;
            margin: 20px 0 10px;
        }

        h4 {
            font-size: 1.3em;
            line-height: 1.4em;
        }

        h5 {
            font-size: 1.25em;
            line-height: 1.4em;
            margin-bottom: 15px;
        }

        h6 {
            font-size: 1em;
            text-transform: uppercase;
            font-weight: 500;
        }

        body {
            background-color: #eeeeee;
            color: #3c4858;
        }

        blockquote p {
            font-style: italic;
        }

        body,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
            font-weight: 300;
            line-height: 1.5em;
        }

        a {
            color: #9c27b0;
            text-decoration: none;
        }

        a:hover,
        a:focus {
            color: #89229b;
            text-decoration: none;
        }

        legend {
            border-bottom: 0;
        }

        a:focus,
        a:active,
        button:active,
        button:focus,
        button:hover,
        button::-moz-focus-inner,
        input[type='reset']::-moz-focus-inner,
        input[type='button']::-moz-focus-inner,
        input[type='submit']::-moz-focus-inner,
        select::-moz-focus-inner,
        input[type='file'] > input[type='button']::-moz-focus-inner {
            outline: 0 !important;
        }

        legend {
            margin-bottom: 20px;
            font-size: 21px;
        }

        output {
            padding-top: 8px;
            font-size: 14px;
            line-height: 1.42857;
        }

        label {
            font-size: 14px;
            line-height: 1.42857;
            color: #aaaaaa;
            font-weight: 400;
        }

        footer {
            padding: 15px 0;
        }

        footer ul {
            margin-bottom: 0;
            padding: 0;
            list-style: none;
        }

        footer ul li {
            display: inline-block;
        }

        footer ul li a {
            color: inherit;
            padding: 15px;
            font-weight: 500;
            font-size: 12px;
            text-transform: uppercase;
            border-radius: 3px;
            text-decoration: none;
            position: relative;
            display: block;
        }

        footer ul li a:hover {
            text-decoration: none;
        }

        /* Change the white to any color ;) */
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
        .swal2-container {
            z-index: 9999 !important;
        }
    }
</style>
