<header class="">
    <div
        class="container mx-auto flex flex-wrap mt-5 mb-0 md:my-5 items-center justify-center"
    >
        <a href="/" class="inline-flex mb-4 md:mb-0 font-header">
            <img
                alt="Sky"
                src="logo.svg"
                class="w-10 sm:w-14 md:w-16 mr-4 flex"
            />
            <span
                class="flex text-3xl leading-none sm:text-5xl md:text-6xl sm:leading-10 md:leading-10 font-medium"
                translate="no"
            >
                sky viajes y turismo
            </span>
        </a>
    </div>
</header>
