<script>
    import PromoCard from './PromoCard.svelte';
    import Card from './Card.svelte';
    import {
        reservation,
        promoPosts,
        blogPosts,
        media,
        configuration,
    } from './store';
    import Carousel from './Carousel.svelte';
    import { slide } from 'svelte/transition';

    $: particlesToShow = 1;
    $: if ($media.lg) {
        particlesToShow = 3;
    } else if ($media.md) {
        particlesToShow = 2;
    } else {
        particlesToShow = 1;
    }

    let showPromotionPost = true;

    let showBlogPost = true;
</script>

<section class="text-gray-600 body-font mt-16">
    <div class="container px-5 md:py-6 mx-auto">
        <div class="flex flex-wrap cards">
            {#each $reservation.reservation_details as itemDetail}
                <div class="p-4 w-full md:w-1/2 lg:w-1/3">
                    <Card item={itemDetail} />
                </div>
            {/each}
        </div>
    </div>
    {#if !!$promoPosts.length}
        <div class="font-header mt-8 mb-8 mx-2">
            <span
                class="flex text-2xl leading-none sm:leading-10 md:leading-10 justify-center p-2 font-bold text-gray-50 bg-gradient-to-r from-blue-400 to-indigo-500 rounded-lg"
                role="button"
                on:click={() => (showPromotionPost = !showPromotionPost)}
            >
                {$configuration?.promotionpost_title || 'Post Promocionales'}
            </span>
        </div>
    {/if}

    {#if showPromotionPost}
        <div class="md:container px-2 md:px-5 md:py-6 mx-auto" transition:slide>
            <!--  -->
            <Carousel
                let:showPrevPage
                let:showNextPage
                {particlesToShow}
                autoplay
                autoplayDuration={5000}
                pauseOnFocus
            >
                <div slot="prev" class="flex items-center">
                    <span
                        class="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 md:w-12 md:h-12 bg-gray-800/30 group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-gray-800/70 group-focus:outline-none "
                        role="button"
                        on:click={showPrevPage}
                    >
                        <svg
                            class="w-5 h-5 sm:w-6 sm:h-6 text-gray-800"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                        <span class="hidden">Previous</span>
                    </span>
                </div>

                {#each $promoPosts as post (post.id)}
                    <PromoCard {post} />
                {/each}

                <div slot="next" class="flex items-center">
                    <span
                        class="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 md:w-12 md:h-12 bg-gray-800/30 group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-gray-800/70 group-focus:outline-none"
                        role="button"
                        on:click={showNextPage}
                    >
                        <svg
                            class="w-5 h-5 sm:w-6 sm:h-6 text-gray-800 "
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M9 5l7 7-7 7"
                            />
                        </svg>
                        <span class="hidden">Next</span>
                    </span>
                </div>
            </Carousel>
        </div>
    {/if}

    <!-- Blog Posts -->

    {#if !!$blogPosts.length}
        <div class="font-header mt-8 mb-8 mx-2">
            <span
                class="flex text-2xl leading-none sm:leading-10 md:leading-10 justify-center p-2 font-bold text-gray-50 bg-gradient-to-r from-blue-400 to-indigo-500 rounded-lg"
                role="button"
                on:click={() => (showBlogPost = !showBlogPost)}
            >
                {$configuration?.blogpost_title || 'Post Blog'}
            </span>
        </div>
    {/if}

    {#if showBlogPost}
        <div class="md:container px-2 md:px-5 md:py-6 mx-auto" transition:slide>
            <!--  -->
            <Carousel
                let:showPrevPage
                let:showNextPage
                {particlesToShow}
                autoplay
                autoplayDuration={5000}
                pauseOnFocus
            >
                <div slot="prev" class="flex items-center">
                    <span
                        class="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 md:w-12 md:h-12 bg-gray-800/30 group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-gray-800/70 group-focus:outline-none "
                        role="button"
                        on:click={showPrevPage}
                    >
                        <svg
                            class="w-5 h-5 sm:w-6 sm:h-6 text-gray-800"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                        <span class="hidden">Previous</span>
                    </span>
                </div>

                {#each $blogPosts as post (post.id)}
                    <PromoCard {post} />
                {/each}

                <div slot="next" class="flex items-center">
                    <span
                        class="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 md:w-12 md:h-12 bg-gray-800/30 group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-gray-800/70 group-focus:outline-none"
                        role="button"
                        on:click={showNextPage}
                    >
                        <svg
                            class="w-5 h-5 sm:w-6 sm:h-6 text-gray-800 "
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M9 5l7 7-7 7"
                            />
                        </svg>
                        <span class="hidden">Next</span>
                    </span>
                </div>
            </Carousel>
        </div>
    {/if}
</section>

<style>
    .cards div:only-child {
        @apply m-auto;
    }
</style>
