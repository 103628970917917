<script>
    import { reservation } from './store';
    import time from './time';
</script>

<div class="bg-white px-1 rounded-md w-full shadow-xl">
    <div class="overflow-x-auto mt-6">
        <table class="table-auto border-collapse w-full">
            <thead class="text-left">
                <tr
                    class="rounded-lg text-sm font-medium text-gray-700"
                    style="font-size: 0.9674rem"
                >
                    <th
                        class="px-4 py-2 bg-gray-200 "
                        style="background-color:#f8f8f8"
                    >
                        Tour
                    </th>
                    <th class="px-4 py-2 " style="background-color:#f8f8f8">
                        Fecha de Inicio
                    </th>
                    <th class="px-4 py-2 " style="background-color:#f8f8f8">
                        Fecha de Finalización
                    </th>
                </tr>
            </thead>
            <tbody class="text-sm font-normal text-gray-700 text-left">
                {#each $reservation.reservation_details as { package: { name, duration }, start_date, comple_date }}
                    <tr
                        class="hover:bg-gray-100 border-b border-gray-200 py-10"
                    >
                        <td class="px-4 py-4">{name}</td>
                        <td class="px-4 py-4">
                            <span
                                class="text-gray-600"
                                use:time={{
                                    timestamp: start_date,
                                    format: 'DD MMMM YYYY [a las] h:mm A',
                                }}
                            >
                                Loading...
                            </span>
                        </td>
                        <td class="px-4 py-4">
                            <span
                                class="text-gray-600"
                                use:time={{
                                    timestamp: comple_date,
                                    format: 'DD MMMM YYYY [a las] h:mm A',
                                }}
                            >
                                Loading...
                            </span>
                        </td>
                    </tr>
                {/each}
            </tbody>
        </table>
    </div>
</div>

<style>
    thead tr th:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    thead tr th:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    tbody tr td:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 0px;
    }
    tbody tr td:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 0px;
    }
</style>
