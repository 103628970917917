<script>
    import Header from './Header.svelte';
    import { reservation, promoPosts, blogPosts, configuration } from './store';

    let hash = window.location.hash.replace('#', '') || null;

    let code = hash;
    let error = null;
    let loading = false;

    const base_url = process.env.dev
        ? `http://${window.location.hostname}:8000`
        : 'https://sky-back.tecno-craft.com';

    fetch(`${base_url}/promotionsposts/?get_all`)
        .then(request => request.json())
        .then(posts => {
            $promoPosts = posts.filter(i => i.post_type === 'PROMOTION');
            $blogPosts = posts.filter(i => i.post_type === 'BLOG');
        });

    fetch(`${base_url}/configurations/promotionpost_title/`)
        .then(request => request.json())
        .then(configPost => {
            $configuration.promotionpost_title = configPost.value;
        });

    fetch(`${base_url}/configurations/blogpost_title/`)
        .then(request => request.json())
        .then(configPost => {
            $configuration.blogpost_title = configPost.value;
        });

    async function submit() {
        loading = true;
        try {
            const request = await fetch(
                base_url + '/reservations/app/?code=' + code.toUpperCase(),
            );

            if (request.ok) {
                const res = await request.json();
                $reservation = res;
            } else {
                error = 400;
            }
        } catch {
            error = 500;
        }
        loading = false;
    }
</script>

<Header />

<section class="text-gray-600 body-font overflow-hidden">
    <form on:submit|preventDefault={submit}>
        <!-- This is an example component -->
        <div class="font-sans">
            <div class="relative flex flex-col py-16 px-10 items-center">
                <div class="relative sm:max-w-sm w-full">
                    <div
                        class="card shadow-lg  w-full h-full rounded-3xl absolute  transform -rotate-6"
                        style="background-color: #007add;"
                    />
                    <div
                        class="card shadow-lg  w-full h-full rounded-3xl absolute  transform rotate-6"
                        style="background-color: #b60356;"
                    />
                    <div
                        class="relative w-full rounded-3xl  px-6 py-4 bg-gray-100 shadow-md"
                    >
                        <label
                            for=""
                            class="block text-sm text-gray-700 text-center font-semibold"
                        >
                            CÓDIGO DE ACCESO
                        </label>
                        <div class="mt-7">
                            <input
                                placeholder="Ingrese código"
                                class="p-4 py-8 mt-1 block w-full border-none bg-white h-11 rounded-xl shadow-lg hover:bg-blue-100 focus:bg-white focus:ring-0 text-4xl uppercase "
                                bind:value={code}
                                disabled={loading}
                                spellcheck="false"
                            />
                            {#if error == 400}
                                <div
                                    class="text-red-500 text-lg mt-4 text-right"
                                >
                                    Codigo incorrecto
                                </div>
                            {:else if error == 500}
                                <div
                                    class="text-red-500 text-lg mt-4 text-right"
                                >
                                    Ocurrió un problema de conexión o servidor,
                                    vuelva a intentar luego.
                                </div>
                            {/if}
                            {#if loading}
                                <div
                                    class="text-gray-500 text-lg mt-4 text-right"
                                >
                                    Conectando...
                                </div>
                            {/if}
                        </div>

                        <div class="mt-7">
                            <button
                                class="bg-blue-500 w-full py-3 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105"
                            >
                                Iniciar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div id="google_translate_element" class="flex justify-center" />
    <script type="text/javascript">
        function googleTranslateElementInit() {
            new google.translate.TranslateElement(
                {
                    pageLanguage: 'es',
                    includedLanguages: 'en,es,fr,de,pt,ko,ar,ru,ja,zh-CN',
                },
                'google_translate_element',
            );
        }
    </script>
    <!-- <Carousel
        autoplay
        autoplayDuration={5000}
        pauseOnFocus
        let:showPrevPage
        let:showNextPage
    >
        <div slot="prev">
            <span
                class="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-gray-800/30 group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-gray-800/70 group-focus:outline-none "
                role="button"
                on:click={showPrevPage}
            >
                <svg
                    class="w-5 h-5 sm:w-6 sm:h-6 text-gray-800"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 19l-7-7 7-7"
                    />
                </svg>
                <span class="hidden">Previous</span>
            </span>
        </div>

        {#each $posts as post (post.id)}
            <PromoCard {post} />
        {/each}

        <div slot="next">
            <span
                class="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-gray-800/30 group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-gray-800/70 group-focus:outline-none"
                role="button"
                on:click={showNextPage}
            >
                <svg
                    class="w-5 h-5 sm:w-6 sm:h-6 text-gray-800 "
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 5l7 7-7 7"
                    />
                </svg>
                <span class="hidden">Next</span>
            </span>
        </div>
    </Carousel> -->
</section>

<!-- xJK+9jhA68S3A8Q+KX9fRTzngX5TvshzClerejjCCSw= -->
<!-- wpuser
A1hzxS1lfOVHaU5DCIjQwtxKK9oGSQ92P5paMqJSb5o= -->
<!-- DV7ASU42adzZ -->

<!-- admin zEc9ulU$f7C7jAuPLQ -->

<!-- Home -> home -->
<!-- Aventura ->  Specials & Packages - Treetops -->
<!-- Habitaciones -> Copia de home -->

<!-- https://github.com/tjinauyeung/svelte-forms-lib
https://github.com/chainlist/svelte-forms
https://github.com/KamyarLajani/svelte-yup
https://github.com/codechips/svelte-yup-form-validation
https://github.com/nathancahill/sveltik
https://github.com/h1b9b/svelte-formik -->
