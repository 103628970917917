<script>
    import Icon from 'svelte-awesome';

    import { externalLink } from 'svelte-awesome/icons';

    export let post;

    let { image_url, title, content, url } = post;
    // image = '';
    // description = '';
</script>

<div class="bg-white tracking-wide px-5">
    <div class="bg-gradient-to-b from-white to-blue-50 h-full rounded-lg">
        <div class="md:flex-shrink-0 bg-gray-200 rounded-t-lg ">
            <a href={url} target="_blank">
                <div
                    class="sv-bg h-48 rounded-t-lg w-full bg-cover bg-center relative"
                    style="--img: url('{image_url ||
                        'https://i.postimg.cc/05Mm1kPS/imageedit-14-9160457711.jpg'}');"
                >
                    <!-- <div
                class="bg-black bg-opacity-50 absolute p-2 rounded-tl-xl rounded-br-lg"
            >
                <span
                    class="text-white opacity-100 font-bold capitalize"
                    use:time={{
                        timestamp: start_date,
                        format: 'dddd DD',
                    }}
                >
                    Loading...
                </span>
            </div> -->
                </div>
            </a>
        </div>
        <div class="px-4 py-2 mt-2 ">
            <a href={url} target="_blank">
                <h2
                    class="title font-bold text-base text-gray-800 text-justify lg:text-left tracking-normal"
                >
                    {title}
                </h2>
            </a>
            {#if content}
                <a href={url} target="_blank">
                    <p
                        class="text-sm text-gray-700 my-4 text-justify md:line-clamp-4 "
                    >
                        {content}
                    </p>
                </a>
            {:else}
                <p
                    class="text-sm text-gray-700 my-4 text-justify md:line-clamp-4 hidden md:block"
                >
                    <br />
                    <br />
                    <br />
                    <br />
                </p>
            {/if}
            <div class="flex justify-end">
                <a
                    href={url}
                    target="_blank"
                    class="flex my-3 justify-end px-4 items-center "
                >
                    <div class="user-logo w-max">
                        <Icon
                            data={externalLink}
                            class="w-8 h-8 sm:w-12 sm:h-12 md:w-16 md:h-16 m-auto flex p-2"
                        />
                    </div>
                    <h2 class="text-sm tracking-tighter text-gray-900">
                        <div>
                            <span class="font-bold">Ver más</span>
                        </div>
                    </h2>
                </a>
            </div>
        </div>
    </div>
</div>

<style>
    /* :root {
        --img: url('https://skyperu.com/wp-content/uploads/2021/01/Colca7-1024x466-1.jpg');
    } */
    .sv-bg {
        background-image: var(--img);
    }
</style>
