<script>
    import ButtonDetails from './ButtonDetails.svelte';
    import Floating from './Floating.svelte';

    import Header from './Header.svelte';

    import Init from './Init.svelte';
    import Services from './Services.svelte';

    import Modal from 'svelte-simple-modal';
    let pageDetail = null;
</script>

<!-- <Services /> -->
{#if pageDetail}
    <Modal>
        <Services bind:pageDetail />
    </Modal>
{:else}
    <Header />
    <ButtonDetails />
    <Init />
{/if}
<Floating />
<!-- <footer class="h-28 mt-12">
</footer> -->
<!-- footer -->
<footer class="border-t mt-12 py-12 px-4">
    <div class="w-full text-center">Gracias ♡ Perú</div>
    <!-- <div class="flex">
        <div class="w-full md:w-1/3 lg:w-1/4">
            <h6 class="font-semibold text-gray-700 mb-4">Company</h6>
            <ul>
                <li>
                    <a href="/" class="block text-gray-600 py-2">Team</a>
                </li>
                <li>
                    <a href="/" class="block text-gray-600 py-2">About us</a>
                </li>
                <li>
                    <a href="/" class="block text-gray-600 py-2">Press</a>
                </li>
            </ul>
        </div>
        
        <div class="w-full md:w-1/3 lg:w-1/4">
            <h6 class="font-semibold text-gray-700 mb-4">Content</h6>
            <ul>
                <li>
                    <a href="/" class="block text-gray-600 py-2">Blog</a>
                </li>
                <li>
                    <a href="/" class="block text-gray-600 py-2">
                        Privacy Policy
                    </a>
                </li>
                <li>
                    <a href="/" class="block text-gray-600 py-2">
                        Terms & Conditions
                    </a>
                </li>
                <li>
                    <a href="/" class="block text-gray-600 py-2">
                        Documentation
                    </a>
                </li>
            </ul>
        </div>
    </div> -->
</footer>
